/* eslint-disable */

import * as d3 from 'd3'
import patternify from '../d3-assets/patternify'
import { thousandsFormat } from '../d3-assets/formatters'

function Treemap (params) {
  var attrs = Object.assign({
    width: 500,
    height: 500,
    margin: {
      top: 20,
      left: 20,
      right: 20,
      bottom: 20
    },
    data: [],
    currencySymbol: null,
    container: 'body'
  }, params)

  let container,
      svg,
      chart,
      chartWidth,
      chartHeight,
      treemap,
      tile = d3.treemapBinary,
      format = thousandsFormat

  function main() {
    container = d3.select(attrs.container)

    // calculate chart width and height
    setDimensions()

    // initialize required layout such as arc, partition etc.
    setChartParams()

    // add svg and other group elements
    addContainers()

    // draw treemap
    addTreemap()
  }

  function setChartParams () {
    treemap = data => {
      const h = d3.hierarchy(data)
        .sum(d => d.amount)
        .sort((a, b) => b.amount - a.amount)

      const t = d3.treemap()
        .tile(tile)
        .size([chartWidth, chartHeight])
        .padding(1)
        .round(true)
      
      return t(h);
    }
  }

  function setDimensions () {
    const bound = container.node().getBoundingClientRect()
    const width = bound.width

    if (width > 0) {
      attrs.width = width
    }

    chartWidth = attrs.width - attrs.margin.left - attrs.margin.right
    chartHeight = attrs.height - attrs.margin.top - attrs.margin.bottom
  }

  function addContainers () {
    svg = patternify({
      container,
      selector: 'treemap-chart-svg',
      tag: 'svg'
    })
    .attr('width', attrs.width)
    .attr('height', attrs.height)

    chart = patternify({
      tag: 'g',
      container: svg,
      selector: 'chart-group'
    })
    .attr('transform', `translate(${attrs.margin.left}, ${attrs.margin.top})`)
  }

  function addTreemap () {
    const root = treemap(attrs.data);

    const leaf = patternify({
      tag: 'g',
      selector: 'leaf',
      data: root.leaves(),
      container: chart
    })
    .each((d, i) => d.id = 'leaf-' + i)
    .attr("transform", d => `translate(${d.x0},${d.y0})`);

    patternify({
      tag: 'title',
      selector: 'title',
      container: leaf,
      data: d => [d]
    })
      .text(d => `${d.ancestors().reverse().map(d => d.data.label).join("/")}\n${format(d.value)}`);
    
    patternify({
      tag: 'rect',
      selector: 'leaf-rect',
      container: leaf,
      data: d => [d]
    })
      .attr("id", d => d.id)
      .attr("fill", d => d.data.color)
      .attr("width", d => d.x1 - d.x0)
      .attr("height", d => d.y1 - d.y0);

    patternify({
      tag: 'clipPath',
      selector: 'clip',
      container: leaf,
      data: d => [d]
    })
      .attr("id", d => d.clipId = 'clip-' + d.id)
      .append("use")
        .attr("xlink:href", d => '#' + d.id);

    patternify({
      tag: 'text',
      selector: 'leaf-text',
      container: leaf,
      data: d => [d]
    })
      .attr("clip-path", d => `url(#${d.clipId})`)
      .selectAll("tspan")
      .data(d => {
        const dt = d.data.label.split(/(?=[A-Z][a-z])|\s+/g);

        let value = format(d.value);
        
        if (attrs.currencySymbol) {
          value += ` ${attrs.currencySymbol}`;
        }

        if (d.data.percent) {
          value += ' (' + d.data.percent + ' %)';
        }

        dt.push(value);

        return dt;
      })
      .join("tspan")
        .attr("x", 3)
        .attr("y", (d, i, nodes) => `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`)
        // .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
        .text(d => d);
  }

  main.render = function () {
    main()

    const rand = Math.floor(Math.random() * 1000000000);
    d3.select(window).on(`resize.${rand}`, main);

    return main
  }

  return main
}

export default Treemap
