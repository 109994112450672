<template>
  <div class="overview">
    <b-container class="main-gradient light" fluid>
      <b-row>
        <b-col no-gutters>
          <b-row>
            <b-col class="chart-container" style="color:white">
              <treemap
                :data="sunburstChartData"
                :currencySymbol="'kr'"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="dashboad bg-light page-wrapper">
      <b-row class="main-content-wrapper" no-gutters>
        <b-col>
          <h1>Sunburst Chart / Treemap Data (n-level hierarchy data)</h1>
          <b>Data:</b><br/>
          <div style="font-family: 'Courier New';font-size:80%;width:80%;background: white;padding:10px;">
            <pre>{{sunburstChartData}}</pre>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import treemap from '@/components/d3charts/treemap/treemap.vue'

export default {
  components: {
    treemap
  },
  data () {
    return {
      sunburstChartData: [
        {
          label: 'equity',
          color: '#FEC600',
          children: [
            { label: 'swedish_equity', color: '#FFF0B2', children: [{ label: 'PLUS Småbolag', amount: 100, color: '#FFF0B2' }, { label: 'PLUS Mikrobolag', color: '#FFF0B2', amount: 500 }] },
            { label: 'international_equity', color: '#FFF0B2', children: [{ label: 'Länsförsäkringar Global Indexnära', color: '#FFF0B2', amount: 5000 }, { label: 'DNB Global Indeks', color: '#FFF0B2', amount: 2500 }, { label: 'Swedbank Robur Access Global', color: '#FFF0B2', amount: 1500 }] }
          ]
        },
        {
          label: 'bonds',
          color: '#F2006B',
          children: [
            { label: 'swedish_bonds', color: '#FFBDCE', children: [{ label: 'Räntefonder Sverige', color: '#FFBDCE', amount: 100 }, { label: 'Realräntor Sverige', color: '#FFBDCE', amount: 250 }] },
            { label: 'international_bonds', color: '#FFBDCE', children: [{ label: 'European bonds', color: '#FFBDCE', children: [{ label: 'SPDR 15-30 year', color: '#FFBDCE', amount: 1400 }, { label: 'iShares 20+ years', color: '#FFBDCE', amount: 1400 }] }, { label: 'USA bonds', color: '#FFBDCE', children: [{ label: 'TLT', amount: 1000, color: '#df8' }, { label: 'Vanguard Total Bond Market', color: '#FFBDCE', amount: 1400 }] }] }
          ]
        },
        {
          label: 'gold',
          color: '#A5ADBA',
          children: [
            { label: 'Xetra Gold 4GLD', amount: 100, color: '#DCE7F7' },
            { label: 'Wisdomtree Physical Tree', color: '#DCE7F7', amount: 250 },
            { label: 'Wisdomtree Swiss Gold', color: '#DCE7F7', amount: 700 }
          ]
        },
        { label: 'alternative', amount: 2300, percent: 10, color: '#ccc' }
      ]
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
  .chart-container {
    padding-top: 50px;
  }
</style>
