<template>
  <div class="treemap" ref="treemap"></div>
</template>

<script>
import Treemap from './treemap'

export default {
  name: 'treemap',
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    currencySymbol: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.chart = Treemap({
      height: 650,
      container: this.$refs.treemap,
      currencySymbol: this.currencySymbol,
      data: {
        label: '',
        children: [
          ...this.data
        ]
      }
    }).render()
  }
}
</script>
